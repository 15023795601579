import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Jumbotron } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Button from '../../Elements/Button';
import AtlasJumbotron from '../../Elements/AtlasJumbotron';

function FeldEntertainment() {
  useEffect(() => {
    document.title = 'Case Study: Feld Entertainment — Atlas Networks';
  }, []);

  return (
    <>
      <AtlasJumbotron
        h1="Atlas Cloud Stories:<br /> Feld Entertainment"
        image="/img/feld/jumbotron.jpg"
        video="/video/feld-entertainment-jumbotron.mp4"
      />

      <Jumbotron className="bg-pink has-bg large-red-jumbotron margin-bottom-100">
        <Container>
          <h2>
            We led FELD Entertainment to migrate their platforms to the Atlas
            Cloud -{' '}
            <strong>
              <em>saving them over six figures annually</em>
            </strong>
            .
          </h2>
        </Container>
      </Jumbotron>

      <Fade bottom>
        <Container className="side-by-side reverse">
          <Row>
            <Col sm={12} md={6}>
              <Fade bottom>
                <img
                  className="feld-logo"
                  src="/img/feld/feld-logo.png"
                  alt="WebOps"
                />
              </Fade>
            </Col>

            <Col sm={12} md={6}>
              <h2>Who.</h2>
              <p>
                Feld Entertainment® is a global powerhouse, known as the
                worldwide leader in producing and presenting live touring family
                entertainment experiences to the masses - bringing people
                together and uplifting the human spirit. Properties include
                Monster Jam®, Monster Energy AMA Supercross, Disney On Ice,
                Disney Live!, Marvel Universe LIVE!, Sesame Street Live!,
                DreamWorks Trolls The Experience and Jurassic World Live Tour.
              </p>
              <p>
                Feld’s reach is huge - entertaining millions of fans each year
                and producing more than 3,500 shows in as many as 75 countries
                on six continents. From 12,000-pound trucks soaring through the
                air to beloved Disney characters skating across the ice, Feld
                creates a range of amazing experiences for children and adults
                alike.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side">
          <Row>
            <Col sm={12} md={6}>
              <img src="/img/feld/sbs1.png" alt="WebOps" />
            </Col>

            <Col sm={12} md={6}>
              <h2>The Challenge.</h2>
              <p>
                Feld Entertainment’s live shows and experiences are driven by
                one thing - ticket sales. At any given moment, Feld’s many
                properties receive around 7,700 requests per minute (more than
                340 million requests per month) and any delineation from that
                results in lost revenue opportunity. Big losses. According to
                Gartner, the average cost per minute of downtime is $5,600 -
                just for normal companies not dependent on ticket sales.
              </p>
              <p>
                Feld came to us in 2017 plagued with unacceptably high downtime
                and scalability challenges across their properties. With all 8
                event-based sites focused solely on ticketing, this was an
                issue. This disruption to their business came with a whole
                ‘nother array of difficulties - reduced employee productivity,
                high frustration rates, and revenue losses.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side reverse feld">
          <Row>
            <Col sm={12} md={6} className="bg-light-blue">
              <h4>
                We spent <em>days</em> with Feld’s internal team to implement
                our Atlas Cloud Workshop - a program designed to fully
                understand the unique infrastructure needs, business goals, and
                challenges for each of Feld’s 8 properties. What we came up with
                was a fine-tuned and unique cloud migration pathway aimed at
                efficiently moving Feld from Acquia to the Atlas Cloud.
              </h4>
            </Col>

            <Col sm={12} md={6}>
              <h2>
                To the <span className="text-underline">Atlas Cloud</span>.
              </h2>
              <p>
                Our team of cloud experts dove into Feld - spending days with
                their core internal team to implement our Atlas Cloud Workshop -
                a program designed to fully understand the unique infrastructure
                needs, business goals, and challenges for each of Feld’s 8
                properties. What we came up with was a fine-tuned and unique
                cloud migration pathway aimed at efficiently moving Feld from
                Acquia to the Atlas Cloud.
              </p>
              <p>
                One by one, we migrated Feld’s properties - from Monster Jam to
                Disney on Ice - to the Atlas Cloud. Each of these properties had
                their own challenges - from tangled backends and inaccessible
                content management systems to terrible customer experience - and
                required complete rebuilds and customized ticketing platforms.
              </p>
              <p>
                The results were simple, immediate, and vital to the financial
                health of Feld’s many brands.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="margin-bottom-100">
          <h2>
            Joining the <span className="text-underline">99.99%</span>.
          </h2>
          <p>
            In 2018 our team, in seamless conjunction with Feld’s internal
            WebOps team, successfully consolidated all 8 properties to the Atlas
            Cloud - resulting in an impressive cost savings of 62-82% per month.
            Hosting with the Atlas Cloud saved Feld Entertainment more than
            $115,259 in one year when compared to their previous vendor - while
            protecting against lost revenue from high downtime.
          </p>
          <p>
            Our sites were faster, more accessible, secure, and reliable
            compared to their last provider, with higher availability than Feld
            previously experienced (we’re talking four nines here).
          </p>
          <p>
            We believe that the sooner we know about a problem, the sooner we
            can fix it.. And that’s why we connect our people - talented
            developers and cloud experts - with the right technology and a
            direct line to you. With our monitoring tools, our team continuously
            tracks website performance and availability all over the world. We
            jump on problems immediately and in real-time - and
            <strong>
              {' '}
              that’s why we can confidently claim an impressive 99.99% SLA
              across Feld Entertainment and all other clients{' '}
            </strong>
            - while saving you money on cloud services.
          </p>
        </Container>
      </Fade>

      <Fade bottom>
        <Jumbotron className="bg-pink has-bg large-red-jumbotron margin-bottom-100">
          <Container>
            <h3>
              <strong>The End Result:</strong>
              <br />
              An impressive cost savings of 62-82% per month, saving Feld
              Entertainment more than $115,259 in one year when compared to
              their previous vendor - while protecting against lost revenue from
              high downtime.
            </h3>
          </Container>
        </Jumbotron>
      </Fade>

      <Container className="text-center">
        <Button
          className="huge"
          text="Find out if we can do the same for you"
          url="/cost-optimization-assessment-form"
        />
      </Container>

      <Fade>
        <Container className="cloud-stories">
          <h2>
            <strong>Read More Cloud Stories</strong>
          </h2>
          <Row>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/harlem-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We saved the Harlem Globetrotters{' '}
                    <span style={{ color: '#ed220d' }}>
                      55% on their annual cloud investment.
                    </span>
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/harlem-globetrotters"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/sun-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We brought Sun to the (Atlas) Cloud - and illuminated
                    opportunities for global growth.
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/sun-hydraulics"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/reh-thumb.jpeg" />
                <Card.Body>
                  <Card.Title>
                    Changing lives through the performing arts, together.
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/ruth-eckerd-hall"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fade>
    </>
  );
}

export default FeldEntertainment;

import React from 'react';
import { ArrowRight } from 'react-feather';

const Button = (props) => {
  const { url, className, text } = props;
  return (
    <a href={url} className={`button ${className}`}>
      {text}
      <span>
        <ArrowRight />
      </span>
    </a>
  );
};

export default Button;

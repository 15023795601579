import React from 'react';
import { Container, Row, Col, Card, Jumbotron } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
// import Fade bottom from 'react-fade-in';
import AtlasJumbotron from '../Elements/AtlasJumbotron';
import Button from '../Elements/Button';

const Homepage = () => {
  return (
    <>
      <AtlasJumbotron
        h2="Harlem Globetrotters saved 55% on their infrastructure by migrating to the Atlas Cloud."
        image="/img/homepage/jumbotron-homepage.jpeg"
        className="margin-bottom-100"
        buttonClass="inverse"
        buttonText="Learn More"
        buttonUrl="/harlem-globetrotters"
      />

      <Fade bottom>
        <Container className="homepage-what-we-do">
          <h2>What we do.</h2>
          <Row className="justify-content-md-center">
            <Col md={5}>
              <p className="text-left">
                We work with organizations to provide technology consulting
                services and implementation strategies designed to help
                organizations navigate complex business challenges, find
                meaningful solutions and deliver impactful results.
              </p>
            </Col>
            <Col md={2}>
              <img src="/img/global/atlas-logo-a.svg" alt="The Atlas A" />
            </Col>
            <Col md={5}>
              <p>
                By blending innovation, software engineering, platform
                integration and cloud services, we build the modern technology
                platforms of tomorrow.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade>
        <Container className="cloud-stories">
          <h2>Partner Success Stories.</h2>
          <Row>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/sun-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We brought Sun to the (Atlas) Cloud - and illuminated
                    opportunities for global growth.
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/sun-hydraulics"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/feld-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We migrated Feld to the Atlas Cloud -{' '}
                    <span style={{ color: '#ed220d' }}>
                      saving them over six-figures annually.
                    </span>
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/feld-entertainment"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/harlem-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We saved the Harlem Globetrotters{' '}
                    <span style={{ color: '#ed220d' }}>
                      55% on their annual cloud investment.
                    </span>
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/harlem-globetrotters"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="homepage-dream-cloud">
          <Jumbotron>
            <div className="left w-50">
              <p>
                <em>Introducing our...</em>
              </p>
              <h1>Dreamcloud</h1>
            </div>
            <div className="right w-50 text-right">
              <h4>
                Learn about our DreamCloud and commitment to community impact.
              </h4>
              <Button text="Learn More" url="/about" />
            </div>
          </Jumbotron>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="homepage-how-can-we-help">
          <h2>How can we help you?</h2>
          <Row>
            <Col sm={12} xl={4}>
              <Card className="h-100">
                <Card.Body className="text-right">
                  <Card.Title>
                    I need to scale my infrastructure to meet unusual demand.
                  </Card.Title>
                  <Button
                    text="Contact A Solutions Architect"
                    url="/schedule-a-call"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} xl={4}>
              <Card className="inverse h-100">
                <Card.Body className="text-right">
                  <Card.Title>
                    I need to reduce and optimize costs to help us through these
                    challenging economic times.
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Cost Optimization Assessment"
                    url="/cost-optimization-assessment-form"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} xl={4}>
              <Card className="h-100">
                <Card.Body className="text-right">
                  <Card.Title>
                    I need to innovate and pivot faster to adjust to changing
                    circumstances.
                  </Card.Title>
                  <Button
                    text="Contact a Development Expert"
                    url="/schedule-a-call"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fade>
    </>
  );
};

export default Homepage;

import React, { useState } from 'react';
import { Menu, Twitter, Facebook, Linkedin, Instagram } from 'react-feather';
import { Row, Container, Nav, Col, Dropdown } from 'react-bootstrap';
import {
  CustomMenu,
  CustomToggle,
  traverseChildren,
} from '../../Services/menuServices';

const Navigation = () => {
  const [isHamCloudOpen, setIsHamCloudOpen] = useState(false);
  const [isHamCoreOpen, setIsHamCoreOpen] = useState(false);
  const [isNavCloudOpen, setIsNavCloudOpen] = useState(false);
  const [isNavCoreOpen, setIsNavCoreOpen] = useState(false);

  return (
    <header id="header">
      <div className="nav-bg" />
      <Container>
        <Row className="align-items-center" id="navrow">
          {/* Dropdown nav */}
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <Menu
                size="30"
                color="#db2446"
                as={CustomToggle}
                id="dropdown-custom-components"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} className="mobile-menu">
              <Nav className="flex-column">
                <Nav.Item>
                  <Nav.Link href="/cloud-stories">Cloud Stories</Nav.Link>{' '}
                </Nav.Item>
                <Dropdown.Item href="/feld-entertainment">
                  Feld Entertainment
                </Dropdown.Item>
                <Dropdown.Item href="/harlem-globetrotters">
                  Harlem Globetrotters
                </Dropdown.Item>
                <Dropdown.Item href="/img-academy">IMG Academy</Dropdown.Item>
                <Dropdown.Item href="/ruth-eckerd-hall">
                  Ruth Eckerd Hall
                </Dropdown.Item>
                <Dropdown.Item href="/sun-hydraulics">
                  Sun Hydraulics
                </Dropdown.Item>
                <Nav.Item>
                  <Nav.Link href="/services">Services</Nav.Link>
                </Nav.Item>
                <Dropdown.Item href="/cloud-migration">
                  Cloud Migration
                </Dropdown.Item>
                <Dropdown.Item href="/managed-services">
                  Managed Services
                </Dropdown.Item>
                <Dropdown.Item href="/application-development">Application Development</Dropdown.Item>
                <Nav.Item>
                  <Nav.Link href="/about">About</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/cost-optimization-assessment-form">
                    Cost Optimization
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/contact">Contact</Nav.Link>
                </Nav.Item>
                <Dropdown.Divider />
                <div className="social">
                  <Row className="justify-content-md-center">
                    <Col xs={1}>
                      <div className="circle bg-red">
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://twitter.com/Inside_Atlas"
                        >
                          <Twitter
                            color="white"
                            fill="white"
                            strokeWidth="0"
                            height="30px"
                            width="30px"
                          />
                        </a>
                      </div>
                    </Col>
                    <Col xs={1}>
                      <div className="circle bg-red">
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://www.linkedin.com/company/33206622/"
                        >
                          <Linkedin
                            color="white"
                            fill="white"
                            strokeWidth="0"
                            height="30px"
                            width="30px"
                          />
                        </a>
                      </div>
                    </Col>
                    <Col xs={1}>
                      <div className="circle bg-red">
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://www.instagram.com/insideatlasnetworks/"
                        >
                          <Instagram color="white" height="30px" width="30px" />
                        </a>
                      </div>
                    </Col>
                    <Col xs={1}>
                      <div className="circle bg-red">
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://www.facebook.com/insideatlasnetworks/"
                        >
                          <Facebook
                            color="white"
                            fill="white"
                            strokeWidth="0"
                            height="30px"
                            width="30px"
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Nav>
            </Dropdown.Menu>
          </Dropdown>

          <a href="/" className="logo">
            <img
              src="/img/global/atlas-logo-wide.svg"
              alt="Atlas Networks logo, dark"
            />
          </a>

          <Nav className="justify-content-end">
            <Dropdown
              onMouseEnter={() => setIsNavCloudOpen(true)}
              onMouseLeave={() => setIsNavCloudOpen(false)}
              show={isNavCloudOpen}
              onToggle={() => setIsNavCloudOpen(!isNavCloudOpen)}
              id="nav-dropdown-cloud"
            >
              <Dropdown.Toggle href="/cloud-stories">
                Cloud Stories
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/feld-entertainment">
                  Feld Entertainment
                </Dropdown.Item>
                <Dropdown.Item href="/harlem-globetrotters">
                  Harlem Globetrotters
                </Dropdown.Item>
                <Dropdown.Item href="/img-academy">IMG Academy</Dropdown.Item>
                <Dropdown.Item href="/ruth-eckerd-hall">
                  Ruth Eckerd Hall
                </Dropdown.Item>
                <Dropdown.Item href="/sun-hydraulics">
                  Sun Hydraulics
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              onMouseEnter={() => setIsNavCoreOpen(true)}
              onMouseLeave={() => setIsNavCoreOpen(false)}
              show={isNavCoreOpen}
              onToggle={() => setIsNavCoreOpen(!isNavCoreOpen)}
              id="nav-dropdown-cloud"
            >
              <Dropdown.Toggle href="/services">Services</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/cloud-migration">
                  Cloud Migration
                </Dropdown.Item>
                <Dropdown.Item href="/managed-services">
                  Managed Services
                </Dropdown.Item>
                <Dropdown.Item href="/application-development">Application Development</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Nav.Item>
              <Nav.Link href="/about">About</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/cost-optimization-assessment-form">
                Cost Optimization
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="nav--contact" href="/contact">
                Contact
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>
      </Container>
    </header>
  );
};

export default Navigation;

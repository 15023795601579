import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HubSpotForm from '../Forms/HubSpotForm';
import AtlasJumbotron from '../Elements/AtlasJumbotron';

const ApplicationDevelopmentInquiry = () => {
  return (
    <>
      <AtlasJumbotron
        header="WebOps baby!"
        image="/img/global/contact-jumbotron.jpg"
      />
      <Container className="contact">
        <Row>
          <Col sm={12} md={9} lg={6}>
            <h2>Begin your Application Development Journey.</h2>
            <p>
              Let our Application Development experts help you optimize your web
              processes and ensure the smooth operations of your
              mission-critical web applications.
            </p>
            <p>
              Whether you need an application from ground-up or help salvaging
              an underperforming application, we can jump in and develop
              solutions in a way that gives you options not available
              “off-the-shelf”. Using proven development methodologies, tools,
              and managed services our global experts can help you improve your
              time to market and reduce risk.
            </p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <div className="conact-info-container">
              <HubSpotForm
                portalId="6343411"
                formId="ada42ea3-8fc4-4951-9fcf-9d68629589c3"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ApplicationDevelopmentInquiry;

import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Jumbotron } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Button from '../../Elements/Button';
import AtlasJumbotron from '../../Elements/AtlasJumbotron';
function HarlemGlobetrotters() {
  useEffect(() => {
    document.title = 'Case Study: Harlem Globetrotters — Atlas Networks';
  }, []);

  return (
    <>
      <AtlasJumbotron
        h1="Atlas Cloud Stories:<br /> Harlem Globetrotters"
        image="/img/homepage/jumbotron-homepage.jpeg"
        video="/video/harlem-globetrotters-jumbotron.mp4"
      />

      <Jumbotron className="bg-pink has-bg large-red-jumbotron margin-bottom-100">
        <Container>
          <h2>
            We saved the Harlem Globetrotters 55% on their annual cloud
            investment, providing a{' '}
            <strong>
              <em>slam dunk</em>
            </strong>{' '}
            for the iconic sporting group.
          </h2>
        </Container>
      </Jumbotron>
      <Fade bottom>
        <Container className="side-by-side reverse">
          <Row>
            <Col sm={12} md={6}>
              <img src="/img/harlem/sbs1.jpg" alt="WebOps" />
            </Col>

            <Col sm={12} md={6}>
              <h2>Who.</h2>
              <p>
                The Harlem Globetrotters (HGT) are a legendary exhibition
                basketball team, known globally for their one-of-a-kind family
                entertainment and great basketball skills for the past 93 years.
                With more than 26,000 exhibition games in 122 countries and
                multiple world tours under their belt, the team combines
                athleticism, theater, and comedy in their style.
              </p>
              <p>
                When the global superstars came to us looking for a strategic
                partner to help drive greater value, impact, and [scalable]
                experiences for their company and customers, we were excited to
                put our cloud expertise to work.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side">
          <Row>
            <Col sm={12} md={6}>
              <img src="/img/harlem/sbs2.jpg" alt="WebOps" />
            </Col>

            <Col sm={12} md={6}>
              <h2>The Challenge.</h2>
              <p>
                Our experience told us that the main challenge for a
                seasonally-driven, tour-based company like the Harlem
                Globetrotters is being able to efficiently expand and shrink
                their infrastructure as needed.
              </p>
              <p>
                With HGT’s normal North American tour running from December to
                April, we knew there would be massive spikes and dips in the
                infrastructure needed to handle variances in web traffic between
                their peak tour dates and off-season.
              </p>
              <p>
                With the intent of identifying opportunities for cost savings
                while increasing speed, reliability, and user experience, we
                collaborated with HGT’s internal technology team and dove into a
                systems audit of their infrastructure, technology stack,
                resource requirements and internal pain points.
              </p>
              <p>
                In completing our audit, we found massive spikes and dips in
                HGT’s online traffic, based largely around HGT’s overall
                seasonal tours, individual city and state performances, and
                presence in the media as they continued to master world-record
                breaking stunts (you know, the usual).
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side reverse feld">
          <h2>
            To the <span className="text-underline">Atlas Cloud</span>.
          </h2>
          <p>
            As a result of our cloud audit, we knew HGT needed an agile
            infrastructure behind its application that could effortlessly scale
            up to handle high loads and scale down afterward - in real-time. We
            aligned with HGT as a strategic cloud partner, in effect migrating
            their infrastructure from Acquia to the Atlas Cloud.
          </p>
          <Row>
            <Col sm={12} md={6} className="bg-light-blue">
              <h4>
                The migration allowed HGT to optimize their cloud architecture
                and network configuration by fine tuning it to the ebb and flow
                of their seasonal needs, while providing increased reliability
                and the best possible experience for the team and their
                attendees.
              </h4>
            </Col>

            <Col sm={12} md={6}>
              <p>
              In understanding HGT’s needs, Atlas Networks developed an ideal solution by creating an autonomous AWS environment that ensures web applications always have the right resources at the right time. To guarantee consistency, we chose to implement AWS’s Auto Scaling and Elastic Load Balancing (ELB) technology that creates new servers when required, and sheds them when they are no longer necessary- ensuring the site is always operating in alignment with its current, real-time needs. The platform utilizes highly customized provisioning scripts with CloudFormation and CodeDeploy, providing HGT with a flexible platform designed to support their evolving needs.
              </p>
              <p>
              During peak traffic periods, when HGT’s servers were consuming more than 60% of their resources (CPU/Memory), its application stack would automatically scale up to distribute the load, effectively reducing the impact on each individual server -  meeting demand without sacrificing performance. “Whether we’re hitting high engagement peaks or regular traffic in the offseason, the Atlas Cloud gives us the level of real-time elasticity that we need. The Atlas Cloud gives us the flexibility to explore new markets with very little risk, because we only pay for the resources we use.”
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>
      <div className="bg-pink">
        <Container className="side-by-side">
          <Row>
            <Col sm={12} md={6}>
              <img
                src="/img/harlem/sbs3.jpeg"
                alt="WebOps"
                className="margin-top-60 margin-bottom-60"
              />
            </Col>

            <Col sm={12} md={6}>
              <h3 className="text-white hgt-end-result--h3">
                <strong>The End Result.</strong>
              </h3>
              <h4 className="text-white hgt-end-result--h4">
                <span className="text-underline">
                  A whopping 55% cost-savings in HGT’s normal annual cloud
                  investment
                </span>{' '}
                compared to their previous vendor, demonstrating Atlas’ ability
                to effectively manage (and surpass) the expectations of a global
                enterprise.
                <br />
                <br />A slam dunk by <em>any</em> definition.
              </h4>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="text-center">
        <Button
          className="huge"
          text="We can do the same for you. Contact us!"
          url="/cost-optimization-assessment-form"
        />
      </Container>

      <Fade>
        <Container className="cloud-stories">
          <h2>
            <strong>Read More Cloud Stories</strong>
          </h2>
          <Row>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/reh-thumb.jpeg" />
                <Card.Body>
                  <Card.Title>
                    Changing lives through the performing arts, together.
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/harlem-globetrotters"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/sun-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We brought Sun to the (Atlas) Cloud - and illuminated
                    opportunities for global growth.
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/sun-hydraulics"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/feld-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We migrated Feld to the Atlas Cloud -{' '}
                    <span style={{ color: '#ed220d' }}>
                      saving them over six-figures annually.
                    </span>
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/feld-entertainment"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fade>
    </>
  );
}

export default HarlemGlobetrotters;

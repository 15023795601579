import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './main.scss';

// Global
import Footer from './Components/Layouts/Footer';
import Navigation from './Components/Layouts/Navigation';

// Top level nav
import Homepage from './Components/Layouts/Homepage';
import Services from './Components/Layouts/Services';
import About from './Components/Layouts/About';

// Cloud Stories
import CloudStories from './Components/Layouts/CloudStories';
import FeldEntertainment from './Components/Layouts/CaseStudies/FeldEntertainment';
import HarlemGlobetrotters from './Components/Layouts/CaseStudies/HarlemGlobetrotters';
import ImgAcademy from './Components/Layouts/CaseStudies/ImgAcademy';
import RuthEckerdHall from './Components/Layouts/CaseStudies/RuthEckerdHall';
import SunHydraulics from './Components/Layouts/CaseStudies/SunHydraulics';

// Capabilities
import CloudMigration from './Components/Layouts/CloudMigration';
import ManagedServices from './Components/Layouts/ManagedServices';
import ApplicationDevelopment from './Components/Layouts/ApplicationDevelopment';

// Forms, why are there so many tho?
import Contact from './Components/Layouts/Contact';
import ScheduleCall from './Components/Layouts/ScheduleCall';
import CostOptimization from './Components/Layouts/CostOptimization';
import ApplicationDevelopmentInquiry from './Components/Layouts/ApplicationDevelopmentInquiry';

function App() {
  return (
    <div className="App">
      <Navigation />
      <BrowserRouter>
        <Switch>
          <Route path="/cloud-stories">
            <CloudStories />
          </Route>
          <Route path="/feld-entertainment">
            <FeldEntertainment />
          </Route>
          <Route path="/harlem-globetrotters">
            <HarlemGlobetrotters />
          </Route>
          <Route path="/img-academy">
            <ImgAcademy />
          </Route>
          <Route path="/ruth-eckerd-hall">
            <RuthEckerdHall />
          </Route>
          <Route path="/sun-hydraulics">
            <SunHydraulics />
          </Route>
          <Route path="/application-development">
            <ApplicationDevelopment />
          </Route>
          <Route path="/managed-services">
            <ManagedServices />
          </Route>
          <Route path="/cloud-migration">
            <CloudMigration />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/schedule-a-call">
            <ScheduleCall />
          </Route>
          <Route path="/cost-optimization-assessment-form">
            <CostOptimization />
          </Route>
          <Route path="/application-development-inquiry">
            <ApplicationDevelopmentInquiry />
          </Route>
          <Route path="/">
            <Homepage />
          </Route>
        </Switch>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HubSpotForm from '../Forms/HubSpotForm';
import AtlasJumbotron from '../Elements/AtlasJumbotron';

const CostOptimization = () => {
  return (
    <>
      <AtlasJumbotron
        h1="Cost Optimization Assessment"
        image="/img/global/contact-jumbotron.jpg"
      />
      <Container className="contact">
        <Row>
          <Col sm={12} md={9} lg={6}>
            <h2>Stop overpaying for Managed Services.</h2>
            <h3>The Atlas Cloud can save you 30% overnight.</h3>
            <p>
              Before you pay your next invoice, let Atlas Networks give you an
              alternative option. As we are all looking for money in the budget,
              complete the questions below and let us save you real money you
              can invest elsewhere in your business overnight.
              <br /> With the information you supply, we will perform a Cloud
              Cost Optimization Assessment which will provide you with the
              knowledge and power to make an informed decision about your
              managed service provider. We guarantee you will be happy with the
              quality of your organizations critical systems and applications as
              well as human customer service that is available at a moments
              notice.
            </p>
            <img
              src="/img/webops/jumbotron.png"
              alt="The Atlas Cloud can save you 30% overnight."
              className="flavor-image"
            />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <div className="conact-info-container">
              <HubSpotForm
                portalId="6343411"
                formId="63444039-f9f4-4d1b-8ba3-35efeeac20a8"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CostOptimization;

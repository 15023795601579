import React from 'react';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
// import FadeIn from 'react-fade-in';
import Button from '../Elements/Button';
import AtlasJumbotron from '../Elements/AtlasJumbotron';

function About() {
  return (
    <>
      <AtlasJumbotron
        h1="Managed Services"
        image="/img/managed-services/jumbotron.png"
      />

      <Container>
        <div className="managed-services-about">
          <h2>Managed Services.</h2>
          <h3>
            Let us manage your cloud complexities so you can get back to doing
            what matters most - driving business value.
          </h3>
          <Row>
            <Col xs={12} md={6}>
              <p>
                We partner with our clients to transform their business by
                providing end-to-end managed cloud services designed by cloud
                experts. After all, the success of your business depends on the
                security, availability, and performance of your cloud
                environment.{' '}
              </p>
            </Col>
            <Col xs={12} md={6}>
              <p>
                With <strong>over 85 years of deep cloud expertise</strong>,
                we’ve listened to our partners and found ways to deliver
                resources, operational support, and cost efficiency like never
                before. So, let us operate your AWS infrastructure on your
                behalf - saving you time, money, and peace of mind.
              </p>
            </Col>
          </Row>
        </div>
      </Container>

      <Container>
        <h2>The Atlas Difference.</h2>
      </Container>
      <Container className="side-by-side cloud-migration overlap">
        <Row>
          <Col sm={12} md={6}>
            <Fade bottom>
              <img
                src="/img/managed-services/sbs1.jpeg"
                alt="Cloud Migration"
              />
            </Fade>
          </Col>
          <Col sm={12} md={6} className="has-bg">
            <div>
              <h3>An Intimate & Reliable Partnership</h3>
              <p>
                Our team is here for you 24/7, ensuring you have the right
                infrastructure and technical resources to ensure the security,
                availability and reliability of your AWS environment.
              </p>

              <Button
                className="inverse"
                text="Learn More About Our Team"
                url="/about"
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="side-by-side cloud-migration overlap reverse">
        <Row>
          <Col sm={12} md={6}>
            <Fade bottom>
              <img
                src="/img/managed-services/sbs2.jpeg"
                alt="Cloud Migration"
              />
            </Fade>
          </Col>
          <Col sm={12} md={6} className="has-bg">
            <div>
              <h3>A Simple & Efficient Approach</h3>
              <p>
                Our collaborative approach ensures scalable solutions that are
                tailored to your company while producing measurable cost saving
                results.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <h2 className="text-center">Managed Services</h2>
        <h3 className="text-center">Core Competencies.</h3>
      </Container>
      <Container className="image-grid">
        <Row>
          <Col xs={12} md={4} className="item text-center">
            <img
              src="/img/red-icons/disaster-recovery.png"
              alt="disaster recovery"
            />
            <h5>Disaster Recovery</h5>
            <p>
              In the event of a total zone failure, Atlas Networks ensures your
              mission-critical applications stay online with little to no
              downtime (99.99% SLA).
            </p>
          </Col>
          <Col xs={12} md={4} className="item text-center">
            <img
              src="/img/red-icons/human-connection.png"
              alt="Human Connection"
            />
            <h5>24/7 Human Connection</h5>
            <p>
              A dedicated Atlas team member is available around the clock to
              ensure you receive the intimacy, reliability, and service you can
              count on.
            </p>
          </Col>
          <Col xs={12} md={4} className="item text-center">
            <img src="/img/red-icons/managed-https.png" alt="Managed HTTPS" />
            <h5>Managed HTTPS</h5>
            <p>
              We run your site securely with HTTPS certificates managed by Atlas
              Networks.
            </p>
          </Col>
          <Col xs={12} md={4} className="item text-center">
            <img src="/img/red-icons/uptime.png" alt="Uptime Through Load" />
            <h5>Uptime Through Load</h5>
            <p>
              We efficiently use computing resources to meet system requirements
              and efficiencies as demand changes and technologies continue to
              evolve.
            </p>
          </Col>
          <Col xs={12} md={4} className="item text-center">
            <img src="/img/red-icons/cdn.png" alt="Global CDN" />
            <h5>Global CDN</h5>
            <p>
              We partner with CloudFlare, the industry leader, to ensure site
              traffic is delivered over a global network of POPs.
            </p>
          </Col>
          <Col xs={12} md={4} className="item text-center">
            <img
              src="/img/red-icons/cloud-backups.png"
              alt="Automated Backups"
            />
            <h5>Automated Backups</h5>
            <p>
              We utilize Automated Application Backup that automatically
              protects your applications, databases and files - ensuring
              efficiency, accuracy, and reliability.
            </p>
          </Col>
          <Col xs={12} md={4} className="item text-center">
            <img
              src="/img/red-icons/migrations.png"
              alt="Managed Site Migrations"
            />
            <h5>Managed Site Migrations</h5>
            <p>
              We seamlessly switch our clients to Atlas Networks’ Managed
              Services Platform with zero downtime.
            </p>
          </Col>
          <Col xs={12} md={4} className="item text-center">
            <img
              src="/img/red-icons/monitoring.png"
              alt="Advanced Site Monitoring"
            />
            <h5>Advanced Site Monitoring</h5>
            <p>
              Using the Atlas Networks Platform, you can view your application
              performance metrics in real time. We systematically monitor your
              application every minute by utilizing Pingdom, the industry
              leading monitoring service.
            </p>
          </Col>
          <Col xs={12} md={4} className="item text-center">
            <img src="/img/red-icons/peace-of-mind.png" alt="Peace of Mind" />
            <h5>Peace of Mind</h5>
            <p>
              The Atlas Networks team is always there for you. Day or night,
              we've got your back.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="text-center">
        <Button
          className="huge"
          text="Let us manage your web presence."
          url="/schedule-a-call"
        />
      </Container>

      <Jumbotron className="managed-services-cta margin-bottom-100">
        <Container>
          <h2>
            100% of respondents describe Atlas Networks as reliable, dedicated,
            or useful - with over 77% using reliable as the first word that
            comes to mind when asked about the organization.
          </h2>
        </Container>
      </Jumbotron>
    </>
  );
}

export default About;

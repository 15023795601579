import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
// import FadeIn from 'react-fade-in';
import Button from '../Elements/Button';
import AtlasJumbotron from '../Elements/AtlasJumbotron';

function About() {
  return (
    <>
      <AtlasJumbotron
        h1="Cloud Migration Services."
        image="/img/cloud-migration/jumbotron.png"
      />

      <Container className="cloud-migration-about">
        <h2>Cloud Migration.</h2>
        <p>
          We work with you to migrate your traditional infrastructure to the
          cloud or optimize your existing cloud, with the goal of reducing your
          operational expenses, increasing efficiency and generating
          more-predictable costs, without sacrificing reliability.
        </p>
      </Container>

      <Container className="side-by-side cloud-migration">
        <Row>
          <Col sm={12} md={6}>
            <Fade bottom>
              <img src="/img/cloud-migration/sbs1.jpeg" alt="Cloud Migration" />
            </Fade>
          </Col>
          <Col sm={12} md={6}>
            <h3>
              <strong>Cost Optimization Assessment</strong>
            </h3>
            <h4>It’s time to get a handle on your cloud spend.</h4>
            <p>
              Let us help you optimize your existing cloud spend - so you can
              reinvest in growing your business.
            </p>
            <p>
              On average, organizations are over budget for cloud spend by a
              whopping 23 percent, and that number is growing. This trend means
              it’s more critical than ever to get a handle on cloud forecasting
              and cost optimization.
            </p>
            <p>
              So, let us take you through our Cost Optimization Assessment - we
              have a goal of saving you 30% on your current infrastructure
              costs- while increasing reliability, security, and speed.
            </p>

            <Button
              className="inverse"
              text="Let Us Assess Your Cloud Spend"
              url="/cost-optimization-assessment-form"
            />
          </Col>
        </Row>
      </Container>

      <Container className="side-by-side reverse cloud-migration">
        <Row>
          <Col sm={12} md={6}>
            <Fade bottom>
              <img src="/img/cloud-migration/sbs2.jpeg" alt="Cloud Migration" />
            </Fade>
          </Col>
          <Col sm={12} md={6}>
            <h3>Atlas Cloud Workshop</h3>
            <h4>
              No matter where you are, the Atlas Cloud Workshop will get you
              where you need to be.
            </h4>
            <p>
              Developed for organizations that are contemplating moving from
              bare metal to the cloud, or organizations with significant Cloud
              assets, the Atlas Cloud Workshop evaluates benefits, risks and
              costs while mapping out a comprehensive strategy designed to
              optimize your business for the cloud.
            </p>
            <p>
              Whether budget or requirements driven, the Atlas Cloud Workshop
              will get you where you need to be.
            </p>
            <h5>How it Works.</h5>
            <p>
              The Atlas Cloud Workshop is designed to get a full, 360°
              understanding of your company’s cloud readiness and infrastructure
              needs, with the goal of defining the best pathways toward cloud
              migration. The process runs through all stages of the cloud
              journey, from evaluating business objectives, application
              assessments and data-based analysis, to optimizing current cloud
              applications and deploying transition plan recommendations.
            </p>
            <h5>The End Result?</h5>
            <p>
              A rock solid cloud migration strategy that will drive your
              business forward while optimizing your technology costs.
            </p>

            <Button
              className="inverse"
              text="Let's Get Started"
              url="/schedule-a-call"
            />
          </Col>
        </Row>
      </Container>

      <Fade>
        <Container className="cloud-stories">
          <h2>Atlas Cloud Stories</h2>
          <p>
            View our case studies to learn how we’ve optimized cloud
            infrastructure to save our clients money.
          </p>
          <Row>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/harlem-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We saved the Harlem Globetrotters{' '}
                    <span style={{ color: '#ed220d' }}>
                      55% on their annual cloud investment.
                    </span>
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/harlem-globetrotters"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/sun-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We brought Sun to the (Atlas) Cloud - and illuminated
                    opportunities for global growth.
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/sun-hydraulics"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/feld-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We migrated Feld to the Atlas Cloud -{' '}
                    <span style={{ color: '#ed220d' }}>
                    saving them over six figures annually.
                    </span>
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/feld-entertainment"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fade>
    </>
  );
}

export default About;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Button from '../Elements/Button';
import AtlasJumbotron from '../Elements/AtlasJumbotron';

function ApplicationDevelopment() {
  return (
    <>
      <AtlasJumbotron
        h1="Application Development."
        image="/img/webops/jumbotron.png"
      />
      <Fade bottom>
        <Container className="webops-about">
          <h2>Application Development.</h2>
          <p>
            From strategy to implementation, we enable you to identify and
            realize unique application opportunities across all business
            functions through our leading technology partners. Our deep
            experience entails assisting clients with business process
            transformation and change management in advancing technology’s
            impact to their business.
          </p>
          <p>We build the tools to accelerate your business growth.</p>
          <p>
            We partner with our clients to develop and deploy custom
            applications that are designed to accelerate the growth of your
            business. From strategy to implementation, our innovative
            application development offerings allow you to optimize
            efficiencies, simplify complexities, and streamline your business.
          </p>
          <Button
            className="inverse"
            text="Let's Get Started"
            url="/application-development-inquiry"
          />
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side">
          <Row>
            <Col sm={12} md={6}>
              <img src="/img/webops/sbs1.png" alt="WebOps" />
            </Col>
            <Col sm={12} md={6}>
              <h2 className="webops-h2">Our Philosophy.</h2>
              <h3 className="webops-h3">A human approach.</h3>
              <p>
                We believe that the strategic use of technology has the power to
                transform your business. At Atlas Networks, we aim to be your
                partner-in-crime and strive within a culture of continual
                collaboration, communication, and transparency. Our experts work
                together to efficiently resolve issues, streamline your business
                complexities, and successfully execute your application’s
                operations while delivering an optimized web experience.
              </p>

              <Button className="inverse" text="Atlas Development" url="/application-development-inquiry" />
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side webops overlap reverse bg-pink has-bg">
          <Row>
            <Col sm={12} md={6}>
              <img src="/img/webops/sbs2.jpeg" alt="Cloud Migration" />
            </Col>
            <Col sm={12} md={6} className="has-bg white">
              <div>
                <h3>Redefining Application Development.</h3>
                <h4>
                  Put 85 years of collaborative experience - at your fingertips.
                </h4>
                <Button
                  className="inverse"
                  text="Let Us Help"
                  url="/application-development-inquiry"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Fade>
    </>
  );
}

export default ApplicationDevelopment;

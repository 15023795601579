import React from 'react';
import { Container, Col, Row, Nav } from 'react-bootstrap';
import { Twitter, Facebook, Linkedin, Instagram } from 'react-feather';
import Button from '../Elements/Button';
import HubSpotForm from '../Forms/HubSpotForm';

function Footer() {
  return (
    <footer>
      <Container fluid className="footer-upper">
        <Container>
          <Row className="justify-content-md-center">
            <Col sm={12} md="auto">
              <h5>Cloud Stories</h5>
              <Nav className="flex-column">
                <Nav.Link href="/feld-entertainment" eventKey="link-2">
                  Feld Entertainment
                </Nav.Link>
                <Nav.Link href="/harlem-globetrotters">
                  Harlem Globetrotters
                </Nav.Link>
                <Nav.Link href="/img-academy" eventKey="link-1">
                  IMG Academy
                </Nav.Link>
                <Nav.Link href="/ruth-eckerd-hall" eventKey="link-3">
                  Ruth Eckerd Hall
                </Nav.Link>
                <Nav.Link href="/sun-hydraulics" eventKey="link-1">
                  Sun Hydraulics
                </Nav.Link>
              </Nav>
            </Col>
            <Col sm={12} md="auto">
              <h5>Services</h5>
              <Nav className="flex-column">
                <Nav.Link href="/cloud-migration">Cloud Migration</Nav.Link>
                <Nav.Link href="/managed-services" eventKey="link-1">
                  Managed Services
                </Nav.Link>
                <Nav.Link href="/application-development" eventKey="link-2">
                  Application Development
                </Nav.Link>
              </Nav>
            </Col>
            <Col sm={12} md="auto">
              <h5>About</h5>
              <Nav className="flex-column">
                <Nav.Link href="/services">Core Capabilities</Nav.Link>
                <Nav.Link href="/about" eventKey="link-1">
                  Leadership
                </Nav.Link>
                <Nav.Link href="/contact" eventKey="link-2">
                  Contact Us
                </Nav.Link>
              </Nav>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="footer-middle">
        <Row className="justify-content-md-center">
          <Col xs={1}>
            <div className="circle bg-red">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://twitter.com/Inside_Atlas"
              >
                <Twitter
                  color="white"
                  fill="white"
                  strokeWidth="0"
                  height="40px"
                  width="40px"
                />
              </a>
            </div>
          </Col>
          <Col xs={1}>
            <div className="circle bg-red">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.linkedin.com/company/33206622/"
              >
                <Linkedin
                  color="white"
                  fill="white"
                  strokeWidth="0"
                  height="40px"
                  width="40px"
                />
              </a>
            </div>
          </Col>
          <Col xs={1}>
            <div className="circle bg-red">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.instagram.com/insideatlasnetworks/"
              >
                <Instagram color="white" height="40px" width="40px" />
              </a>
            </div>
          </Col>
          <Col xs={1}>
            <div className="circle bg-red">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.facebook.com/insideatlasnetworks/"
              >
                <Facebook
                  color="white"
                  fill="white"
                  strokeWidth="0"
                  height="40px"
                  width="40px"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="footer-lower">
        <Container>
          <Row>
            <Col md={3}>
              <h3 className="text-uppercase footer-subscribe-h3">
                <strong>Subscribe</strong>
              </h3>
              <p className="footer-subscribe-p">
                Sign up with your email address to receive news and updates.
              </p>
              <HubSpotForm
                portalId="6343411"
                formId="d86547e2-5f90-465c-9598-1d30d1278f50"
                css=".submitted-message { color: #ffffff; }"
              />
            </Col>
            <Col md={{ span: 4, offset: 1 }} className="footer-lower-middle">
              <h3 className="text-uppercase">
                <strong>What can we do for you?</strong>
              </h3>
              <p className="footer-what-we-do">
                We work with you to optimize your infrastructure and reduce your
                operational expenses, increasing efficiency while generating
                more-predictable costs, without sacrificing reliability.
              </p>
              <div className="text-center">
                <Button text="Get Started" url="/schedule-a-call" />

                <a
                  className="aws-partner"
                  href="https://aws.amazon.com/partners/find/partnerdetails/?id=0010L00001tBjjHQAS"
                >
                  <img
                    src="/img/global/aws-partner.png"
                    alt="AWS Partner Logo"
                  />
                </a>
              </div>
            </Col>
            <Col className="footer-address-block" md={{ span: 3, offset: 1 }}>
              <img
                src="/img/global/atlas-logo-white.svg"
                className="footer-logo"
                alt="Atlas logo, dark"
              />
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.google.com/maps/place/513+Central+Ave,+Sarasota,+FL+34236/@27.3406113,-82.5450484,17z/data=!3m1!4b1!4m5!3m4!1s0x88c3400d0a1dfb35:0x3347edc65fcce4bf!8m2!3d27.3406066!4d-82.5428544"
              >
                <p>513 Central Ave</p>
                <p>Second Floor</p>
                <p>Sarasota FL 34236</p>
              </a>
              <p className="tel">
                Tel: <a href="tel:941-900-4119">(941) 900-4119</a>
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="footer-bottom">
        <Container>
          <Row>
            <Col sm={12} md={4}>
              <a href="https://www.globalprivacycenter.com/privacy-policy">
                Privacy Policy
              </a>
            </Col>
            <Col sm={12} md={4} className="text-center">
              <a href="https://www.globalprivacycenter.com/">
                Data Collection Practice
              </a>
            </Col>
            <Col sm={12} md={4} className="text-right">
              Copyright {new Date().getFullYear()} Atlas Networks, LLC
            </Col>
          </Row>
        </Container>
      </Container>
    </footer>
  );
}

export default Footer;

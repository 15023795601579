import React from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import AtlasJumbotron from '../Elements/AtlasJumbotron';
import Button from '../Elements/Button';

const Services = () => {
  return (
    <>
      <AtlasJumbotron
        h1="Our Services"
        body="Through intimacy of your challenges, together, we transform your organization to grow customers and revenue, while ensuring peace of mind by managing all of your critical infrastructure."
        image="/img/services/jumbotron.jpeg"
      />
      <Fade bottom>
        <Container className="text-center services-header">
          <h2>Our Core Capabilities Defined.</h2>
          <p>
            We work with you to transform your organization - reducing your
            operational expenses, increasing efficiency and generating more
            predictable costs, without sacrificing reliability.
          </p>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side">
          <Row>
            <Col sm={12} md={6}>
              <img
                src="/img/services/cloud-migration.png"
                alt="Cloud Migration"
              />
            </Col>
            <Col sm={12} md={6}>
              <h2>Cloud Migration</h2>
              <p>
                Atlas Networks helps you evaluate and redefine your ideal cloud
                architecture, plan your migration roadmap, and execute a
                seamless migration to the Atlas Cloud - achieving all your
                business needs.
              </p>
              <p>
                At Atlas Networks, our cloud experts connect your business needs
                to a right-sized cloud architecture, while supporting you every
                step of the way.
              </p>

              <Button
                className="inverse"
                text="Migration Services"
                url="/cloud-migration"
              />
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side reverse">
          <Row>
            <Col sm={12} md={6}>
              <img
                src="/img/services/managed-services.png"
                alt="Cloud Migration"
              />
            </Col>
            <Col sm={12} md={6}>
              <h2>Managed Services</h2>
              <h3>Managing the cloud - made simple and efficient.</h3>
              <p>
                Let the cloud experts at Atlas Networks manage your cloud
                complexities, so you can do what matters most - drive business
                value.
              </p>
              <p>
                Our team is here for you 24/7, ensuring you have the right
                infrastructure and technical resources to ensure the security,
                availability and reliability of your AWS environment.
              </p>

              <Button
                className="inverse"
                text="Managed Services"
                url="/managed-services"
              />
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side">
          <Row>
            <Col sm={12} md={6}>
              <img src="/img/services/webops.png" alt="WebOps" />
            </Col>
            <Col sm={12} md={6}>
              <h2>Application Development</h2>
              <p>
                From strategy to implementation, we enable you to identify and
                realize unique application opportunities across all business
                functions through our leading technology partners. Our deep
                experience entails assisting clients with business process
                transformation and change management in advancing technology’s
                impact to their business.
              </p>

              <Button
                className="inverse"
                text="Application Development Consulting"
                url="/application-development"
              />
            </Col>
          </Row>
        </Container>
      </Fade>

      <Jumbotron
        className="services-cta margin-bottom-100"
        style={{ backgroundImage: `url(/img/services/cta.jpeg)` }}
      >
        <Container>
          <h2>Interested in evaluating your cloud footprint?</h2>
          <Button text="Contact Us." url="/contact" />
        </Container>
      </Jumbotron>
    </>
  );
};

export default Services;

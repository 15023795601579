import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Jumbotron } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Button from '../../Elements/Button';
import AtlasJumbotron from '../../Elements/AtlasJumbotron';


function ImgAcademy() {
  useEffect(() => {
    document.title = 'Case Study: IMG Academy — Atlas Networks';
  }, []);

  return (
    <>
      <AtlasJumbotron
        h1="Atlas Cloud Stories:<br /> IMG Academy"
        image="/img/img/jumbotron.jpg"
        video="/video/img-academy-jumbotron.mp4"
      />

      <Jumbotron className="bg-pink has-bg large-red-jumbotron margin-bottom-100">
        <Container>
          <h3>
            Atlas has solidified IMG Academy’s position as an industry leader
            equipped with cutting-edge cloud infrastructure, unparalleled growth
            in revenue, international customer acquisition, and ultimately,{' '}
            <strong>peace of mind</strong>.
          </h3>
        </Container>
      </Jumbotron>
      <Fade bottom>
        <Container className="side-by-side reverse">
          <Row>
            <Col xs={12} md={6}>
              <img src="/img/img/sbs1.jpg" alt="WebOps" />
            </Col>

            <Col xs={12} md={6}>
              <h2>Who.</h2>
              <p>
                IMG Academy is known worldwide as an industry-leading
                institution for athletic and performance development through
                their boarding school, camp programs, events and tournaments,
                pro and team training, corporate retreats, and more. Their
                variety of offerings and capabilities, coupled with an elite
                reputation, makes Atlas Networks a significant partner in
                ensuring IMG’s infrastructures speed, agility, and reliability -
                the same performance standards they hold for their athletes.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side">
          <Row>
            <Col xs={12} md={6}>
              <img src="/img/img/sbs2.jpg" alt="WebOps" />
            </Col>

            <Col xs={12} md={6}>
              <h2>The Challenge.</h2>
              <p>
                As a globally-renowned academy, IMG needed to implement
                fast-paced, scalable, and evolving technologies to maintain
                their cutting edge position as an industry leader. They had
                challenges managing their array of complex applications across
                marketing and sales channels and needed a strategic Web
                Operations (WebOps) model that could be deployed across their
                entire organization.
              </p>
              <p>
                In addition to the base infrastructure, IMG's in-house
                development and marketing team required systems that would allow
                them to fluidly deploy content and features from their local
                environments to production, while simultaneously eliminating
                downtime and protecting against any foreseeable challenges.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side reverse">
          <Row>
            <Col xs={12} md={3}>
              <h5>Technology</h5>
              <p>
                GitLab
                <br />
                Drupal
                <br />
                Amazon Web Services&nbsp;
                <br />— EC2
                <br />— S3
                <br />— CloudFront
                <br />— Lambda&nbsp;
                <br />— DynamoDB <br />— API&nbsp;Gateway&nbsp;
                <br />
                Elasticsearch <br />
                Symfony
                <br />
                CloudFlare
                <br />
                React
                <br />
                Merchant e-Solutions <br />
                Alibaba&nbsp;
                <br />
                Cloud Docker <br />
                Wordpress <br />
                Hubspot <br />
                Marketo <br />
                Olark
              </p>
            </Col>

            <Col xs={12} md={9}>
              <h2>Building IMG Academy’s International Empire.</h2>
              <p>
                We deployed our unique WebOps model to drive success across
                every aspect of IMG’s business. After securing ownership over
                customer growth, revenue acquisition, and web platforms and
                systems, our model opened the pathway for IMG’s growing success.
              </p>
              <p>
                A high-performance infrastructure was established for IMG’s
                Drupal-based platform, Symfony eCommerce back-end, and
                React-based interactive tools managed in AWS to deliver a 99.99%
                reliability rating. All critical web requests were routed
                through CloudFlare with a dynamic cache expiration system that
                was controlled within Drupal to allow for efficient updating.
                AWS Lambda microservices allowed for content personalization and
                business intelligence support with zero maintenance beyond
                managing CI systems.{' '}
              </p>
              <p>
                Atlas also worked with internal developers to enhance their
                workflow with Docker containers. This opportunity allowed for
                increased efficiency, reduced time interludes, and provided
                continuous integration systems that will enable updates to be
                distributed globally within seconds. A schedule of periodic
                penetration and breach tests envelope the system with peace of
                mind.{' '}
              </p>
              <p>
                Additionally, along with our Chinese-based partner, our team
                established a replica of the platform within the Alibaba Cloud
                Datacenter, which is synchronized with the rest of the platform
                outside of China. Working closely with IMG’s Chinese office and
                our Alibaba partner, we obtained a coveted ICP license to
                operate a website within mainland China. This has allowed IMG to
                maintain a multilingual and internationally distributed presence
                on domain-specific TLDs and allowed access to the massive 1.3B
                Chinese audience which, otherwise, would have been difficult to
                reach for IMG.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side">
          <Row>
            <Col xs={12} md={6}>
              <img src="/img/img/sbs3.png" alt="WebOps" />
            </Col>

            <Col xs={12} md={6}>
              <h2>The Results.</h2>
              <p>
                Atlas’ success with IMG has resulted in a 12-year partnership.
                Over time, our WebOps model has become critical to the
                successful momentum of the organization. By consistently
                achieving year-over-year growth, increased leads, and revenue
                through eCommerce, we have aided in delivering a heightened
                growth trajectory in international markets. Technology and
                WebOps standards deployed and maintained by Atlas has greatly
                contributed to IMG success, taking our partnership trajectory
                from $80K in online revenues to over $110+ million.
              </p>
              <p>
                By implementing the foundation of a WebOps model, Atlas has
                solidified IMG’s position as an industry leader with
                cutting-edge cloud-based infrastructure, unparalleled growth in
                revenue, international customer acquisition, and ultimately,
                peace of mind.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Jumbotron className="bg-pink has-bg large-red-jumbotron margin-top-100 margin-bottom-100">
          <Container>
            <h3>
              Working closely with Atlas Networks has provided us with the
              opportunity to significantly scale our business, expand our
              digital horizons, and implement strategies we may not have
              otherwise had the internal resources for. Their professionalism,
              timeliness, and knowledge has been invaluable, and we are excited
              for the future of our business while working alongside Atlas.
            </h3>
            <p className="author text-right">- IMG Digital Marketing</p>
          </Container>
        </Jumbotron>
      </Fade>

      <Fade>
        <Container className="cloud-stories">
          <h2>
            <strong>Read More Cloud Stories</strong>
          </h2>
          <Row>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/reh-thumb.jpeg" />
                <Card.Body>
                  <Card.Title>
                    Changing lives through the performing arts, together.
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/ruth-eckerd-hall"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/sun-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We brought Sun to the (Atlas) Cloud - and illuminated
                    opportunities for global growth.
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/sun-hydraulics"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/feld-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We migrated Feld to the Atlas Cloud -{' '}
                    <span style={{ color: '#ed220d' }}>
                      saving them over six-figures annually.
                    </span>
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/feld-entertainment"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fade>
    </>
  );
}

export default ImgAcademy;

import React from 'react';
import { Container, Row, Col, Card, Jumbotron } from 'react-bootstrap';
// import Fade from 'react-Fade-in';
import Fade from 'react-reveal/Fade';
import Button from '../Elements/Button';
import AtlasJumbotron from '../Elements/AtlasJumbotron';

function About() {
  return (
    <>
      <AtlasJumbotron
        header="Our Services"
        h1="About us."
        body="Since the beginning, we’ve invested in and incubated the best talent we could find. Now? We’re cloud veterans."
        image="/img/about/jumbotron.png"
        className="about"
      />

      <Fade bottom>
        <Container className="about-approach">
          <h2 className="text-center">The Atlas Approach.</h2>
          <Row>
            <Col sm={12} md={4}>
              <h3>Agile.</h3>
              <Card>
                <Card.Img variant="top" src="/img/about/agile.png" />
                <Card.Body>
                  <Card.Title>We’re constantly evolving.</Card.Title>
                  <Card.Text>
                    We continuously work with our clients to better understand
                    the nuances of their industry - tailoring our expertise to
                    your environment and ensuring practical, intimate, and
                    strategic solutions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <h3>Productive.</h3>
              <Card>
                <Card.Img variant="top" src="/img/about/productive.jpeg" />
                <Card.Body>
                  <Card.Title>We’re always available.</Card.Title>
                  <Card.Text>
                    Our team is here for you 24/7, ensuring you have the right
                    infrastructure and technical resources to ensure the
                    security, availability and reliability of your AWS
                    environment.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <h3>Human.</h3>
              <Card>
                <Card.Img variant="top" src="/img/about/human.jpeg" />
                <Card.Body>
                  <Card.Title>We’re cloud services veterans.</Card.Title>
                  <Card.Text>
                    Behind Atlas Networks is our core team - a highly-skilled
                    collection of dedicated engineers. That’s more than 85 years
                    of cloud and application development experts at your fingertips.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="about-satisfaction">
          <Jumbotron>
            <h3 className="text-center">
              Atlas Networks has a customer satisfaction score of{' '}
              <span className="text-underline">100%</span>.
            </h3>
          </Jumbotron>

          <Button text="Talk to us." url="/contact" />
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="about-commitment">
          <Row>
            <Col xs={12} md={6}>
              <h2>A commitment to our community.</h2>
              <p>
                One of our founding principles is the idea that “Wherever we
                are, whatever we do, we have a duty to serve our community.”
                This principle reverberates across our company.
              </p>
              <p>
                We continuously encourage meaningful community impact through
                our connection with DreamLarge, a local benefit corporation.
                Through this partnership, our team is provided limitless
                opportunities to create meaningful + positive impact in the
                community - whether through philanthropy or volunteerism.{' '}
              </p>
              <Button
                url="https://dreamlarge.org/"
                className="inverse"
                text="Learn More About DreamLarge"
              />
            </Col>
            <Col xs={12} md={6} className="text-right">
              <img
                src="/img/about/about-dreamlarge.jpg"
                alt="DreamLarge Weekends"
              />
            </Col>
          </Row>
        </Container>
      </Fade>

      {/* <Fade bottom>
        <Container className="about-team">
          <h2 className="text-center">The Atlas Boys (and Girl).</h2>
          <Row>
            <Col sm={12} md={4}>
              <Card>
                <Card.Img variant="top" src="/img/about/Anand.png" />
                <Card.Body>
                  <Card.Title>Anand Pallegar</Card.Title>
                  <Card.Text>Founder</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card>
                <Card.Img variant="top" src="/img/about/Steve.png" />
                <Card.Body>
                  <Card.Title>Steve Sanders</Card.Title>
                  <Card.Text>Director of Operations</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card>
                <Card.Img variant="top" src="/img/about/Michael.png" />
                <Card.Body>
                  <Card.Title>Michael Mei</Card.Title>
                  <Card.Text>Director of Technology</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={4}>
              <Card>
                <Card.Img variant="top" src="/img/about/Colin.png" />
                <Card.Body>
                  <Card.Title>Colin Elias</Card.Title>
                  <Card.Text>Director of Client Strategy</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card>
                <Card.Img variant="top" src="/img/about/Keith.png" />
                <Card.Body>
                  <Card.Title>Keith Caulkins</Card.Title>
                  <Card.Text>Lead Solutions Architect</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card>
                <Card.Img variant="top" src="/img/about/Courtney.png" />
                <Card.Body>
                  <Card.Title>Courtney Adesile</Card.Title>
                  <Card.Text>Lead Solutions Architect</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col sm={12} md={4}>
              <Card>
                <Card.Img variant="top" src="/img/about/Haylee.png" />
                <Card.Body>
                  <Card.Title>Haylee Millar</Card.Title>
                  <Card.Text>Solutions Architect</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fade> */}

      <Fade bottom>
        <Container className="about-partners">
          <h2 className="text-center">Strategic Partners.</h2>
          <Row>
            <Col xs={6} md={3}>
              <a href="https://aws.amazon.com/">
                <img src="/img/about/aws.png" alt="Amazon Web Services logo" />
              </a>
            </Col>
            <Col xs={6} md={3}>
              <a href="https://www.cloudflare.com/">
                <img src="/img/about/cloudflare.png" alt="CloudFlare logo" />
              </a>
            </Col>
            <Col xs={6} md={3}>
              <a href="https://www.drupal.org/">
                <img src="/img/about/drupal.png" alt="Drupal logo" />
              </a>
            </Col>
            <Col xs={6} md={3}>
              <a href="https://about.gitlab.com/">
                <img src="/img/about/gitlab.png" alt="Gitlab logo" />
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={3}>
              <a href="https://newrelic.com/">
                <img src="/img/about/newrelic.jpg" alt="NewRelic logo" />
              </a>
            </Col>
            <Col xs={6} md={3}>
              <a href="https://www.shopify.com/">
                <img src="/img/about/shopify.png" alt="Shopify logo" />
              </a>
            </Col>
            <Col xs={6} md={3}>
              <a href="https://www.tessituranetwork.com/">
                <img src="/img/about/tessitura.png" alt="Tessitura logo" />
              </a>
            </Col>
            <Col xs={6} md={3}>
              <a href="https://www.pingdom.com/">
                <img src="/img/about/pingdom.png" alt="Pingdom logo" />
              </a>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <div className="about-reviews">
          <Container>
            <h2 className="text-center">The reviews are in.</h2>
            <Row>
              <Col xs={12} md={8}>
                <p>
                  “Working closely with Atlas Networks has provided us with the
                  opportunity to significantly scale our business, expand our
                  digital horizons, and implement strategies we may not have
                  otherwise had the internal resources for. Their
                  professionalism, timeliness, and knowledge has been
                  invaluable, and we are excited for the future of our business
                  while working alongside Atlas.”
                </p>
                <p className="author">- IMG ACADEMY</p>
              </Col>
              <Col xs={12} md={4}>
                <div className="spacer" />
                <p>
                  “From the very beginning, Atlas Networks has instilled peace
                  of mind when it comes to our infrastructure across the entire
                  organization.”
                </p>
                <p className="author">- RUTH ECKERD HALL</p>
              </Col>
            </Row>
          </Container>
        </div>
      </Fade>
    </>
  );
}

export default About;

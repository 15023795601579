import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HubSpotForm from '../Forms/HubSpotForm';
import AtlasJumbotron from '../Elements/AtlasJumbotron';

const ScheduleCall = () => {
  return (
    <>
      <AtlasJumbotron
        header="Schedule A Call"
        image="/img/global/contact-jumbotron.jpg"
      />
      <Container className="contact">
        <Row>
          <Col sm={12} md={9} lg={6}>
            <h2>Schedule a call.</h2>
            <p>
              Begin your journey towards cost efficiency and technical
              optimization by contacting one of our solution architects.
            </p>
            <p>
              You’ll receive a confirmation from our team within one business
              day.{' '}
            </p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <div className="conact-info-container">
              <HubSpotForm
                portalId="6343411"
                formId="da682d2e-bfbb-404e-92d4-50ab76a1faf0"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ScheduleCall;

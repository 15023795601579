import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HubSpotForm from '../Forms/HubSpotForm';
import AtlasJumbotron from '../Elements/AtlasJumbotron';
import Map from '../Forms/Map';

const Contact = () => {
  return (
    <>
      <AtlasJumbotron
        h1="Contact Us"
        image="/img/global/contact-jumbotron.jpg"
      />
      <Container className="contact">
        <Row>
          <Col sm={12} md={9} lg={6}>
            <h2>Talk With us</h2>
            <p>
              If you have any questions or comments, please contact us via email
              or phone. We’re here for you - day or night.
            </p>
            <HubSpotForm
              portalId="6343411"
              formId="d37820cb-83d5-4d99-8fca-e59358f325a4"
            />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <div className="conact-info-container">
              <h5>
                <strong>Atlas Networks</strong>
              </h5>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.google.com/maps/place/513+Central+Ave,+Sarasota,+FL+34236/@27.3406113,-82.5450484,17z/data=!3m1!4b1!4m5!3m4!1s0x88c3400d0a1dfb35:0x3347edc65fcce4bf!8m2!3d27.3406066!4d-82.5428544"
              >
                <address>
                  <p>513 Central Avenue, 2nd Floor </p>
                  <p>Sarasota, FL 34236</p>
                </address>
              </a>
              <h5>
                <strong>Email</strong>
              </h5>
              <a href="mailto:hello@atlasnetworks.com">
                <p>hello@atlasnetworks.com</p>
              </a>
              <h5>
                <strong>Phone</strong>
              </h5>
              <a href="tel:941-900-4119">
                <p>(941) 900-4119</p>
              </a>
              <Map />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;

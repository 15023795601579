import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Button from './Button';

const AtlasJumbotron = (props) => {
  const {
    image,
    h1,
    h2,
    body,
    className,
    hideslash,
    buttonUrl,
    buttonClass,
    buttonText,
    video,
  } = props;
  return (
    <Jumbotron
      className={`jumbotron--header ${className}`}
      style={{ backgroundImage: `url(${image})` }}
    >
      {video && (
        <video muted autoPlay noControls loop preload="auto">
          <source src={video} type="video/mp4" />
        </video>
      )}
      <div className="overlay" />
      <Container>
        {!hideslash && (
          <img
            src="/img/global/atlas-logo-a-under.svg"
            alt="Atlas A"
            className="atlas-a hide-mobile"
          />
        )}
        {h1 && <h1 dangerouslySetInnerHTML={{ __html: h1 }} />}
        {h2 && <h2 dangerouslySetInnerHTML={{ __html: h2 }} />}
        {body && <p>{body}</p>}
        {buttonUrl && buttonClass && buttonText && (
          <div className="jumbotron-button">
            <Button className={buttonClass} text={buttonText} url={buttonUrl} />
          </div>
        )}
      </Container>
    </Jumbotron>
  );
};

export default AtlasJumbotron;

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Button from '../Elements/Button';
import AtlasJumbotron from '../Elements/AtlasJumbotron';

function CloudStories() {
  return (
    <>
      <AtlasJumbotron
        h1="Cloud Stories."
        image="/img/cloud-stories/jumbotron.jpeg"
      />

      <Container className="cloud-stories-about">
        <p>View our case studies and learn how we've helped companies identify cost-saving approaches to streamline their cloud investments - allowing them to re-invest in stabilizing and growing their business.</p>
      </Container>

      <Fade>
        <Container className="cloud-stories list">
          <Row>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/feld-thumb.jpg" />
                <Card.Body>
                  <Card.Title>Feld Entertainment</Card.Title>
                  <p>We migrated Feld to the Atlas Cloud - saving them over six-figures annually.</p>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/feld-entertainment"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/harlem-thumb.jpg" />
                <Card.Body>
                  <Card.Title>Harlem Globetrotters</Card.Title>
                  <p>We saved the Harlem Globetrotters 55% on their annual cloud investment.</p>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/harlem-globetrotters"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/img/thumb.jpg" />
                <Card.Body>
                  <Card.Title>IMG Academy</Card.Title>
                  <p>We took IMG from $80K in online revenues to over $110+ million with Application Development and Cloud Infrastructure.</p>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/img-academy"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/reh/thumb.jpeg" />
                <Card.Body>
                  <Card.Title>Ruth Eckerd Hall</Card.Title>
                  <p>We saved REH over $30K annually- allowing them to invest more in changing lives through performing arts.</p>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/ruth-eckerd-hall"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/sun-thumb.jpg" />
                <Card.Body>
                  <Card.Title>Sun Hydraulics</Card.Title>
                  <p>We brought Sun to the (Atlas) Cloud - and illuminated opportunities for global growth.</p>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/sun-hydraulics"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fade>
    </>
  );
}

export default CloudStories;

import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Jumbotron } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Button from '../../Elements/Button';
import AtlasJumbotron from '../../Elements/AtlasJumbotron';


function SunHydraulics() {
  useEffect(() => {
    document.title = 'Case Study: Sun Hydraulics — Atlas Networks';
  }, []);

  return (
    <>
      <AtlasJumbotron
        h1="Atlas Cloud Stories:<br /> Sun Hydraulics"
        image="/img/sun/jumbotron.jpg"
      />

      <Jumbotron className="bg-pink has-bg large-red-jumbotron margin-bottom-100">
        <Container>
          <h3>
            We brought Sun Hydraulics into the clouds, illuminating new
            opportunities for their global growth.
          </h3>
        </Container>
      </Jumbotron>

      <Fade bottom>
        <Container className="side-by-side reverse">
          <Row>
            <Col xs={12} md={6}>
              <img src="/img/sun/sbs1.png" alt="WebOps" />
            </Col>

            <Col xs={12} md={6}>
              <h2>Who.</h2>
              <p>
                Sun Hydraulics, a subsidiary of Helios Technologies, is a
                Sarasota-based leading designer and manufacturer of
                high-performance screw-in hydraulic cartridge valves and
                manifolds that control the force, speed and motion as integral
                components in fluid power systems.
              </p>
              <p>
                They have a global presence, with offices in the US, Europe,
                Asia, and the Pacific and distribution partners spread across
                the world.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side">
          <Row>
            <Col xs={12} md={6}>
              <img src="/img/sun/sbs2.jpeg" alt="The Challenge" />
            </Col>

            <Col xs={12} md={6}>
              <h2>The Challenge.</h2>
              <p>
                Sun wanted to extend its global presence while increasing their
                experience’s accessibility, efficiency, and scalability for
                their distributors and partners.
              </p>
              <p>
                Since opening their doors in 1970, Sun Hydraulics operated
                within the confines of a bare metal infrastructure - housed
                onsite in their Sarasota headquarters. They faced the typical
                risks and challenges of a traditional physical infrastructure -
                heavy time and maintenance requirements, scalability issues,
                high downtime and increased latency due to their geographic
                location.
              </p>
              <p>
                They wanted to be able to better support their global partners
                and desired the speed, security, reliability, and real-time
                flexibility that the Atlas Cloud offered… so we got to work.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="margin-bottom-100">
          <h2>Bringing Sun to the Atlas Cloud</h2>
          <p>
            We needed a better understanding of their business and user needs in
            order to implement a fine-tuned and unique cloud migration strategy
            that would successfully (and efficiently) bring Sun to the cloud.
          </p>
          <p>
            We implemented our <strong>Atlas Cloud Workshop</strong> - a program
            intended to get a full, 360-degree understanding of Sun’s cloud
            readiness and infrastructure needs, with the goal of defining the
            best pathways toward cloud migration. The process runs through all
            stages of the cloud journey, from evaluating business objectives,
            application assessments and data-based analysis, to optimizing
            current cloud applications and deploying transition plan
            recommendations.
          </p>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side feld margin-bottom-100">
          <Row>
            <Col sm={12} md={6} className="bg-light-blue">
              <h4>
                Together with Sun’s team, we strategically implemented our cloud
                roadmap which smoothly and efficiently migrated Sun’s web
                presence to the Atlas Cloud. We improved site performance,
                engineering efficiency, and UX, while ensuring long-term
                scalability and sustainability of their online infrastructure.
                This allowed them to spend less time maintaining infrastructure
                and more time executing strategy to grow their global
                enterprise.
              </h4>
            </Col>

            <Col sm={12} md={6}>
              <h2>The End Result.</h2>
              <p>
                The end result was this: a fully integrated and individualized
                cloud migration strategy that focused on migrating Sun’s web
                presence to the cloud, while fulfilling their strategic business
                objectives.{' '}
              </p>
              <p>
                This provided the concrete steps needed to successfully move
                Sun’s web application from their physical infrastructure to the
                Atlas Cloud - ensuring them the increased speed, security,
                resiliency, and scalability they needed to better scale and
                support their global distribution partners. As an added perk,
                Sun no longer needed to continuously maintain their evolving
                website via physical infrastructure, saving them financial
                resources and lessening the labor demands on their internal
                team.
              </p>
              <p>
                We continue to maintain their cloud infrastructure to this day.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Jumbotron className="bg-pink has-bg large-red-jumbotron margin-bottom-100">
          <Container>
            <h3>
              <strong>The End Result:</strong>
            </h3>
            <h3>
              <br />A fully integrated and individualized cloud migration
              strategy that focused on migrating Sun’s web presence to the
              cloud, while fulfilling their strategic business objectives.
            </h3>
          </Container>
        </Jumbotron>
      </Fade>
      <Container className="text-center">
        <Button
          className="huge"
          text="We can do the same for you. Contact us!"
          url="/cost-optimization-assessment-form"
        />
      </Container>

      <Fade>
        <Container className="cloud-stories">
          <h2>
            <strong>Read More Cloud Stories</strong>
          </h2>
          <Row>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/harlem-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We saved the Harlem Globetrotters
                    <span style={{ color: '#ed220d' }}>
                      55% on their annual cloud investment.
                    </span>
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/harlem-globetrotters"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/reh-thumb.jpeg" />
                <Card.Body>
                  <Card.Title>
                    Changing lives through the performing arts, together.
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/ruth-eckerd-hall"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/feld-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We migrated Feld to the Atlas Cloud -{' '}
                    <span style={{ color: '#ed220d' }}>
                      saving them over six-figures annually.
                    </span>
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/feld-entertainment"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fade>
    </>
  );
}

export default SunHydraulics;

import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Jumbotron } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Button from '../../Elements/Button';
import AtlasJumbotron from '../../Elements/AtlasJumbotron';

function RuthEckerdHall() {
  useEffect(() => {
    document.title = 'Case Study: Ruth Eckerd Hall — Atlas Networks';
  }, []);

  return (
    <>
      <AtlasJumbotron
        h1="Atlas Cloud Stories:<br /> Ruth Eckerd Hall Experience"
        image="/img/reh/jumbotron.jpeg"
      />

      <Jumbotron className="bg-pink has-bg large-red-jumbotron margin-bottom-100">
        <Container>
          <h3>
            Changing lives through the performing arts - <em>together</em>.
          </h3>
        </Container>
      </Jumbotron>

      <Fade bottom>
        <Container className="side-by-side reverse">
          <Row>
            <Col xs={12} md={6}>
              <img src="/img/reh/sbs1.jpeg" alt="WebOps" />
            </Col>

            <Col xs={12} md={6}>
              <h2>Who.</h2>
              <p>
                As one of America’s top concert halls in the United States
                (ranked #7, nationally), Ruth Eckerd Hall (REH) is considered an
                acoustically perfect Performance Arts venue. A performance held
                at its theatre is an uninterrupted, almost mesmerizing
                experience for the spectator. This 73,000 square foot venue
                hosts more than 465,000 visitors a year, and includes a range of
                external venues.
              </p>
              <p>
                We’ve been working with REH for many years - and when they
                wanted to move to reimagine their digital experience, they knew
                exactly who to go to.
              </p>
              <p>
                With our deep experience in the arts and nonprofit sector, we
                understood exactly how to be the best partner-in-crime in REH’s
                journey to change lives through the performing arts.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side">
          <Row>
            <Col xs={12} md={6}>
              <img src="/img/reh/sbs2.jpeg" alt="WebOps" />
            </Col>

            <Col xs={12} md={6}>
              <h2>The Challenge.</h2>
              <p>
                REH was feeling increasingly limited by its physical
                infrastructure. It was difficult to scale, and time-consuming
                and expensive to maintain.
              </p>
              <p>
                With their entire business revolving around ticket sales, we
                knew that that’s where their focus should lie - not dealing with
                the hassle of their physical infrastructure. And, at the end of
                the day, the work done to maintain the architecture didn’t
                directly serve the REH’s mission of “changing lives through the
                performing arts”.
              </p>
              <p>
                With several venues and shows associated with the Ruth Eckerd
                Hall Experience, we wanted to simplify any unnecessary steps. At
                the time, they facilitated ticketing in conjunction with
                Tessitura, the largest ticketing platform in the Arts world.
              </p>
              <p>
                With their season soon in full swing, we knew we needed to come
                up with a plan that would effectively and efficiently migrate
                their site to the Atlas Cloud, while also phasing out
                Tessitura’s full API integration in favor of the newer TN
                Express Web (TNEW).
              </p>
              <p>
                We knew they needed a technical partner who would be by their
                side every step of the way (that’s us!).
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="margin-bottom-100">
          <h2>The Solution</h2>
          <p>
            We spent several days collaborating with REH’s team and leading them
            through our Atlas Cloud Workshop, a program designed to get a 360
            degree view of an organization's cloud readiness and infrastructure
            needs, with the goal of defining an individualized roadmap toward
            cloud migration. We went into the process intending to better
            understand their team, structure, technical needs, and overarching
            business objectives, while pinpointing exactly how to optimize costs
            and save them money.
          </p>
          <p>
            With their season soon in full swing, we came up with a cloud
            migration strategy that would effectively and efficiently migrate
            their site to the Atlas Cloud, while also phasing out Tessitura’s
            full API integration in favor of the newer TN Express Web (TNEW).
          </p>
          <p>
            We worked with them every step of the way to implement this plan and
            ensure that the process and outcomes fit within their overarching
            business goals.
          </p>
        </Container>
      </Fade>

      <Fade bottom>
        <Container className="side-by-side feld margin-bottom-100">
          <Row>
            <Col sm={12} md={6} className="bg-light-blue">
              <h4>
                When compared to the leading cloud hosting provider, REH saved
                close to $30,000&nbsp; annually, allowing them to reinvest in
                their mission of changing lives through the performing arts.
              </h4>
            </Col>

            <Col sm={12} md={6}>
              <h2>A Lasting Relationship.</h2>
              <p>
                We quickly identified a cloud migration strategy that included a
                targeted methodology, best practices and unique tools - all
                tailored to fit REH and effectively migrate their applications
                to the cloud.
              </p>
              <p>
                The switch to the Atlas Cloud brought along a beautiful user
                experience and growth of their e-commerce revenue - due to the
                increased stability and availability the Atlas Cloud offers a
                99.99% SLA.
              </p>
              <p>
                When compared to the leading cloud hosting provider, REH saved
                close to $30,000 annually.{' '}
              </p>
              <p>
                But, by and large, the best outcome of the move to the Atlas
                Cloud was the deep and personal relationship that we solidified
                with REH and their staff. Since our initial connection with them
                in 2016, we’ve become partners-in-crime, and are there for any
                (and every) technology need.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade bottom>
        <Jumbotron className="bg-pink has-bg large-red-jumbotron margin-bottom-100">
          <Container>
            <h3>
              <strong>The End Result:</strong>
            </h3>
            <h3>
              <br />
              By migrating to the Atlas Cloud, Ruth Eckerd Hall was able to save
              over $30,000 annually which enabled them to reinvest in the
              community and continue to change lives through the performing
              arts.
            </h3>
          </Container>
        </Jumbotron>

        <Container className="text-center">
          <Button
            className="huge"
            text="We can do the same for you. Contact us!"
            url="/cost-optimization-assessment-form"
          />
        </Container>
      </Fade>

      <Fade>
        <Container className="cloud-stories">
          <h2>
            <strong>Read More Cloud Stories</strong>
          </h2>
          <Row>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/harlem-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We saved the Harlem Globetrotters{' '}
                    <span style={{ color: '#ed220d' }}>
                      55% on their annual cloud investment.
                    </span>
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/harlem-globetrotters"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/sun-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We brought Sun to the (Atlas) Cloud - and illuminated
                    opportunities for global growth.
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/sun-hydraulics"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="h-100">
                <Card.Img variant="top" src="/img/global/feld-thumb.jpg" />
                <Card.Body>
                  <Card.Title>
                    We migrated Feld to the Atlas Cloud -{' '}
                    <span style={{ color: '#ed220d' }}>
                      saving them over six-figures annually.
                    </span>
                  </Card.Title>
                  <Button
                    className="inverse"
                    text="Read More"
                    url="/feld-entertainment"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fade>
    </>
  );
}

export default RuthEckerdHall;

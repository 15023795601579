import React from 'react';
import HubspotForm from 'react-hubspot-form';

const HubSpotForm = ({ portalId, formId, css }) => {
  return (
    <HubspotForm
      portalId={portalId}
      formId={formId}
      cssRequired={css}
      onSubmit={() => console.log('Submit!')}
      // onReady={(form) => console.log('Form ready!')}
      loading={<div>Loading...</div>}
    />
  );
};

export default HubSpotForm;
